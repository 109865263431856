exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-where-are-the-maldives-tsx": () => import("./../../../src/pages/blog/where-are-the-maldives.tsx" /* webpackChunkName: "component---src-pages-blog-where-are-the-maldives-tsx" */),
  "component---src-pages-destinations-tsx": () => import("./../../../src/pages/destinations.tsx" /* webpackChunkName: "component---src-pages-destinations-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-maldives-index-tsx": () => import("./../../../src/pages/maldives/index.tsx" /* webpackChunkName: "component---src-pages-maldives-index-tsx" */),
  "component---src-pages-maldives-planning-attractions-tsx": () => import("./../../../src/pages/maldives/planning/attractions.tsx" /* webpackChunkName: "component---src-pages-maldives-planning-attractions-tsx" */),
  "component---src-pages-maldives-planning-flights-tsx": () => import("./../../../src/pages/maldives/planning/flights.tsx" /* webpackChunkName: "component---src-pages-maldives-planning-flights-tsx" */),
  "component---src-pages-maldives-planning-general-info-tsx": () => import("./../../../src/pages/maldives/planning/general-info.tsx" /* webpackChunkName: "component---src-pages-maldives-planning-general-info-tsx" */),
  "component---src-pages-maldives-planning-hotels-tsx": () => import("./../../../src/pages/maldives/planning/hotels.tsx" /* webpackChunkName: "component---src-pages-maldives-planning-hotels-tsx" */),
  "component---src-pages-maldives-planning-index-tsx": () => import("./../../../src/pages/maldives/planning/index.tsx" /* webpackChunkName: "component---src-pages-maldives-planning-index-tsx" */),
  "component---src-pages-maldives-planning-packing-list-tsx": () => import("./../../../src/pages/maldives/planning/packing-list.tsx" /* webpackChunkName: "component---src-pages-maldives-planning-packing-list-tsx" */),
  "component---src-pages-maldives-planning-time-to-visit-tsx": () => import("./../../../src/pages/maldives/planning/time-to-visit.tsx" /* webpackChunkName: "component---src-pages-maldives-planning-time-to-visit-tsx" */),
  "component---src-pages-maldives-planning-transportation-tsx": () => import("./../../../src/pages/maldives/planning/transportation.tsx" /* webpackChunkName: "component---src-pages-maldives-planning-transportation-tsx" */),
  "component---src-pages-maldives-planning-weather-tsx": () => import("./../../../src/pages/maldives/planning/weather.tsx" /* webpackChunkName: "component---src-pages-maldives-planning-weather-tsx" */),
  "component---src-pages-maldives-planning-who-is-it-for-tsx": () => import("./../../../src/pages/maldives/planning/who-is-it-for.tsx" /* webpackChunkName: "component---src-pages-maldives-planning-who-is-it-for-tsx" */)
}

